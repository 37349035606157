<template>
  <div class="col-12 col-lg-6 c g">
    <div class="card card-body">
        <p>اعدادات الارسال (من الواتس الذكي)</p>
        <div class="form-group">
          <label for="">رقم الجوال لحسابك في الواتس الذكي</label>
          <input type="text"
            class="form-control" v-model="settings.smart_username">
        </div>
        <div class="form-group">
          <label for="">كلمة المرور لحسابك في الواتس الذكي</label>
          <input type="text"
            class="form-control" v-model="settings.smart_password">
        </div>
        <div class="col-12 text-center g" @click="saveWhats()">
            <button class="btn btn-primary">
                <i class="fa fa-save"></i>
                حفظ & تحديث البيانات
            </button>
        </div>
        <div class="col-12" v-if="error">
            <div class="alert alert-danger text-center g">
                <h4>
                    {{ error }}
                </h4>
            </div>
        </div>
        <div class="col-12" v-if="settings.device_title">
            <br>
            <div class="card card-body border" style="border: 2px green solid !important">
                <span>
                    <i class="fa fa-check-square"></i>
                    الجهاز المتصل: {{ settings.device_title }} &mdash; {{ settings.device_phone }}
                </span>
            </div>
        </div>
    </div>
    <div class="card card-body">
        <p>المدن</p>
        <div class="form-group">
          <label for="">اسم المدينة</label>
          <input type="text"
            class="form-control" v-model="settings.city_title">
        </div>
        <div class="col-12 text-center g" @click="addCity()">
            <button class="btn btn-primary">
                <i class="fa fa-save"></i>
                اضافة مدينة
            </button>
        </div>
        <div class="col-12" v-if="error">
            <div class="alert alert-danger text-center g">
                <h4>
                    {{ error }}
                </h4>
            </div>
        </div>
        <hr>
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        المدينة
                    </th>
                    <th>
                        حذف
                    </th>
                </thead>
                <tbody>
                    <tr v-for="city in settings.cities" :key="city.id">
                        <td>
                            <input type="text" style="border:none;" v-model="city.title" @change="saveCities()">
                        </td>
                        <td>
                            <button class="btn btn-sm text-danger fa fa-trash" @click="deleteCity(city.id)"></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            settings: {
                cities: []
            },
            error: "",
        }
    },
    created(){
        var g = this;
        $.post(api + '/admin/general/settings', {
            jwt: this.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                if(r.response){
                    if(r.response.send_withdraw != 'yes' && r.response.send_withdraw != 'true'){
                        r.response.send_withdraw = false;
                    }
                    g.settings = r.response
                }
            }
        }).fail(function(){
            alert("حدث مشكلة في الاتصال")
        })
    },
    methods: {
        saveWhats(){
        var g = this;
        g.error = ""
        $.post(api + '/admin/general/settings-save', {
            jwt: this.jwt,
            smart_username: this.settings?.smart_username,
            smart_password: this.settings?.smart_password,
            settings: "whats"
        }).then(function(r){
            if(r.status != 100){
                g.error = r.response
            }else{
                location.reload()
            }
        }).fail(function(){
            g.error = "حدث مشكلة في الاتصال"
        })
        },
        saveCities(){
            var g = this;
            $.post(api + '/admin/general/settings-save', {
                    jwt: this.jwt,
                    cities: this.settings?.cities,
                    settings: "cities"
                }).then(function(r){
                    if(r.status != 100){
                        g.error = r.response
                    }
                }).fail(function(){
                    g.error = "حدث مشكلة في الاتصال"
                })
        },
        addCity(){
            var g =this;
            if(!g.settings.cities){
                g.settings.cities = []
            }
            if(this.settings.city_title && this.settings.city_title != ""){
                g.error = ""
                g.settings.cities.push({
                    id: Math.random().toString().split(".")[1],
                    title: g.settings.city_title
                })
                g.settings.city_title = ""
                g.saveCities()
            }
        },
        deleteCity(id){
            var g = this;
            if(confirm("متأكد من حذف المدينة نهائياً؟")){
                var arr = [];
                g.settings.cities.forEach(element => {
                    if(element.id != id){
                        arr.push(element)
                    }
                });
                g.settings.cities = arr;
                g.saveCities()
            }
        }
    },
}
</script>

<style>

</style>